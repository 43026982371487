import React, { ReactNode, useState, useEffect } from 'react'
import { Tab } from '@next-nx/shared-ui/atoms'
import styles from './TabGroup.module.sass'
import { sendGTMData } from '../../../utils/gtm'
import { ProdottoType } from '@next-nx/utils/types'

interface Props {
  className?: string
  tabs: {
    p: ProdottoType
    key: string
    label?: string
    icon?: ReactNode
    banner?: string
    content: ReactNode
  }[]
  bgColor: '' | 'primary'
  triggerSize?: 'md' | 'lg'
  clearSelectedLineePreventivatore?: () => void
  setProdottoPreventivatore: (p: ProdottoType) => void
}

const TabGroup = (props: Props) => {
  const {
    className = '',
    tabs = [],
    triggerSize = 'md',
    bgColor = '',
    clearSelectedLineePreventivatore = () => {},
    setProdottoPreventivatore = () => undefined,
  } = props

  const [activeTab, setActiveTab] = useState(tabs.length > 0 ? tabs[0].key : '')

  useEffect(() => {
    if (activeTab && tabs.length > 0) {
      const t = tabs.filter((el) => el.key == activeTab)
      if (t && t.length > 0) {
        setProdottoPreventivatore(t[0].p)
      }
    }
  }, [activeTab])

  const handleKeyDown = (e: any, key: string) => {
    const currentIndex = tabs.findIndex((tab) => tab.key === key)
    if (currentIndex === -1) return

    let newIndex
    if (e?.key === 'ArrowRight') {
      newIndex = (currentIndex + 1) % tabs.length
    } else if (e?.key === 'ArrowLeft') {
      newIndex = (currentIndex - 1 + tabs.length) % tabs.length
    } else {
      return
    }

    const newActiveTab = tabs[newIndex].key
    setActiveTab(newActiveTab)

    const newTabElement = document?.getElementById(
      `${tabs[newIndex].label?.replaceAll(' ', '_').slice(0, 15)}_buttonpanel`
    )
    if (newTabElement) {
      newTabElement.focus()
    }
  }

  return tabs.length > 0 ? (
    <div
      className={`${styles.tabGroup} ${bgColor ? styles[`tabGroup--${bgColor}`] : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      <div className={styles.triggers} role="tablist">
        {tabs.map((tab) => (
          <Tab.Trigger
            key={tab.key}
            label={tab.label}
            size={triggerSize}
            icon={tab.icon}
            active={tab.key === activeTab}
            bgColor={bgColor}
            onClick={() => {
              setActiveTab(tab.key)
              clearSelectedLineePreventivatore()
              sendGTMData({
                event: 'GAevent',
                eventCategory: 'preventivatore',
                eventAction: 'menu',
                eventLabel: tab?.label?.toGTMFormat(),
              })
            }}
            buttonId={`${tab?.label?.replaceAll(' ', '_').slice(0, 15)}_buttonpanel`}
            id={`${tab?.label?.replaceAll(' ', '_').slice(0, 15)}_tabpanel`}
            labelId={`${tab?.label?.replaceAll(' ', '_').slice(0, 15)}_label`}
            tabIndex={tab.key === activeTab ? 0 : -1}
            onKeyDown={(e) => handleKeyDown(e, tab.key)}
          />
        ))}
      </div>
      <div className={styles.contents}>
        {tabs.map((tab) => (
          <Tab
            id={`${tab?.label?.replaceAll(' ', '_').slice(0, 15)}_tabpanel`}
            labelId={`${tab?.label?.replaceAll(' ', '_').slice(0, 15)}_label`}
            active={tab.key === activeTab}
            key={tab.key}
            banner={tab.banner}
          >
            {tab.content}
          </Tab>
        ))}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default TabGroup
