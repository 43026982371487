import React from 'react'
import { MessageError } from '../Icons'
import styles from './FormError.module.sass'

interface Props {
  id?: string
  message?: string | string[]
  visible?: boolean
  className?: string
}

const FormError = (props: Props) => {
  const { id = '', message = '', visible = false, className = '' } = props

  return visible && message ? (
    <div className={`${styles.formError} ${className || ''}`} role="alert">
      <span className={styles.icon}>
        <MessageError />
      </span>
      <span
        id={id || undefined}
        className={styles.errormessage}
        dangerouslySetInnerHTML={{ __html: message as string }}
      />
    </div>
  ) : (
    <></>
  )
}

export default FormError
