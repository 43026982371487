import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
  title?: string
}

function LogoAssicurazioniMono({ className, title = '' }: Props) {
  return (
    <IconBase name="logo-assicurazioni-mono" w={278} h={33} className={className}>
      {title && <title>{title}</title>}
      <path
        d="M64.5595 3.75877C66.7115 3.75877 68.9613 4.39338 70.8199 5.71142L69.4015 7.51763C67.8853 6.49248 66.6626 5.90669 64.7551 5.90669C61.4782 5.90669 58.886 7.95698 58.886 10.9348C58.886 13.9126 61.4782 15.9629 64.7551 15.9629C66.7604 15.9629 68.0809 15.3283 69.6949 14.2543L71.1133 16.0605C69.2548 17.3785 66.8582 18.1108 64.6084 18.1108C60.0598 18.1108 55.8047 15.4259 55.8047 10.886C55.8047 6.29722 60.1087 3.66113 64.6084 3.66113L64.5595 3.75877Z"
        stroke="none"
      />
      <path
        d="M81.3843 10.9349C83.4874 10.9349 84.8569 10.0562 84.8569 8.54285C84.8569 7.07836 83.5363 6.24848 81.3354 6.24848H76.9825V10.9349H81.3354H81.3843ZM73.999 4.00293H81.58C85.1015 4.00293 87.9382 5.4186 87.9382 8.49404C87.9382 10.886 86.1775 12.1553 83.7809 12.6922L88.3784 17.9644H84.8569L80.6996 13.0828H76.9825V17.9644H73.999V4.00293Z"
        stroke="none"
      />
      <path
        d="M100.997 0L98.6978 2.44082H96.1545L97.9153 0H101.045H100.997ZM91.3125 13.9127V3.95413H103.98V6.19968H94.296V9.56801H103.197V11.7648H94.296V13.9127C94.296 15.1331 94.8829 15.7189 96.0567 15.7189H104.273V17.9644H95.372C92.8287 17.9644 91.3125 16.4511 91.3125 13.9127Z"
        stroke="none"
      />
      <path
        d="M114.399 15.7677C117.822 15.7677 120.072 13.8638 120.072 11.0325C120.072 8.20114 117.773 6.24848 114.399 6.24848H110.877V15.7677H114.399ZM107.894 4.00293H114.203C119.583 4.00293 123.202 6.78546 123.202 10.9837C123.202 15.1819 119.583 17.9644 114.203 17.9644H107.894V4.00293Z"
        stroke="none"
      />
      <path d="M126.381 4.00293V18.0132H129.364V4.00293H126.381Z" stroke="none" />
      <path
        d="M146.091 6.24848H140.711V18.0132H137.728V6.24848H132.348V4.00293H146.091V6.24848Z"
        stroke="none"
      />
      <path
        d="M163.453 12.4482L160.714 6.88311L157.976 12.4482H163.502H163.453ZM164.578 14.6449H156.851L155.188 18.0132H152.106L159.345 4.05176H162.426L169.665 18.0132H166.241L164.578 14.6449Z"
        stroke="none"
      />
      <path
        d="M179.349 3.75878C181.501 3.75878 183.75 4.39339 185.609 5.71143L184.191 7.51764C182.674 6.49249 181.452 5.9067 179.544 5.9067C176.267 5.9067 173.675 7.90817 173.675 10.9348C173.675 13.9126 175.974 16.0117 179.446 16.0117C181.012 16.0117 182.43 15.5723 183.408 14.9377V12.2528H179.202V10.1049H186.44V15.9629C184.778 17.1833 182.381 18.1596 179.349 18.1596C174.066 18.1596 170.545 15.0354 170.545 10.9348C170.545 6.34604 174.849 3.70996 179.349 3.70996V3.75878Z"
        stroke="none"
      />
      <path
        d="M197.544 10.9349C199.647 10.9349 201.016 10.0562 201.016 8.54285C201.016 7.07836 199.696 6.24848 197.495 6.24848H193.142V10.9349H197.495H197.544ZM190.158 4.00293H197.739C201.261 4.00293 204.097 5.4186 204.097 8.49404C204.097 10.886 202.337 12.1553 199.94 12.6922L204.538 17.9644H201.016L196.859 13.0828H193.142V17.9644H190.158V4.00293Z"
        stroke="none"
      />
      <path d="M207.962 4.00293V18.0132H210.945V4.00293H207.962Z" stroke="none" />
      <path
        d="M223.172 3.75877C225.324 3.75877 227.574 4.39338 229.432 5.71142L228.014 7.51763C226.498 6.49248 225.275 5.90669 223.367 5.90669C220.042 5.90669 217.498 7.95698 217.498 10.9348C217.498 13.9126 220.09 15.9629 223.367 15.9629C225.373 15.9629 226.693 15.3283 228.307 14.2543L229.726 16.0605C227.867 17.3785 225.47 18.1108 223.221 18.1108C218.672 18.1108 214.417 15.4259 214.417 10.886C214.417 6.29722 218.721 3.66113 223.221 3.66113L223.172 3.75877Z"
        stroke="none"
      />
      <path
        d="M233.883 10.9836C233.883 13.815 236.231 16.0117 239.606 16.0117C243.029 16.0117 245.328 13.7661 245.328 10.9836C245.328 8.15227 242.98 5.95553 239.606 5.95553C236.182 5.95553 233.883 8.20108 233.883 10.9836ZM230.753 10.9836C230.753 7.02949 234.421 3.75879 239.606 3.75879C244.79 3.75879 248.458 7.02949 248.458 10.9836C248.458 14.9377 244.79 18.2084 239.606 18.2084C234.421 18.2084 230.753 14.9377 230.753 10.9836Z"
        stroke="none"
      />
      <path d="M251.246 18.0132V4.00293H254.23V15.7677H263.131V18.0132H251.246Z" stroke="none" />
      <path
        d="M265.039 13.9615V4.00293H277.707V6.24848H268.023V9.61682H276.924V11.8135H268.023V13.9615C268.023 15.1819 268.609 15.7677 269.783 15.7677H278V18.0132H269.099C266.555 18.0132 265.039 16.4999 265.039 13.9615Z"
        stroke="none"
      />
      <path
        d="M65.0484 28.6547L62.9942 24.5053L60.94 28.6547H65.0973H65.0484ZM65.8799 30.3145H60.0596L58.788 32.8529H56.4893L61.9671 22.3574H64.2659L69.7437 32.8529H67.1515L65.8799 30.3145Z"
        stroke="none"
      />
      <path
        d="M70.3302 31.3401L71.4062 29.8267C72.7756 30.8519 74.6342 31.2912 76.2482 31.2912C77.9111 31.2912 79.2806 30.8031 79.2806 30.022C79.2806 29.1921 77.7644 28.8992 76.0036 28.5087C73.6071 28.0205 70.7214 27.3859 70.7214 25.2868C70.7214 23.3342 73.1669 22.1138 75.9547 22.1138C77.8622 22.1138 79.8675 22.6507 81.2859 23.6271L80.2099 25.1404C79.036 24.2617 77.422 23.7735 75.9547 23.7735C74.4385 23.7735 73.0202 24.2617 73.0202 24.9939C73.0202 25.6774 74.3896 25.9703 76.0526 26.312C78.498 26.8001 81.5304 27.4347 81.5304 29.7291C81.5304 31.7794 79.036 32.9998 76.1993 32.9998C73.9984 32.9998 71.8463 32.3652 70.2812 31.3401H70.3302Z"
        stroke="none"
      />
      <path
        d="M83.4376 31.3401L84.5136 29.8267C85.8831 30.8519 87.7416 31.2912 89.3556 31.2912C91.0185 31.2912 92.388 30.8031 92.388 30.022C92.388 29.1921 90.8718 28.8992 89.1111 28.5087C86.7145 28.0205 83.8289 27.3859 83.8289 25.2868C83.8289 23.3342 86.2743 22.1138 89.0622 22.1138C90.9696 22.1138 92.9749 22.6507 94.3933 23.6271L93.3173 25.1404C92.1435 24.2617 90.5294 23.7735 89.0622 23.7735C87.546 23.7735 86.1276 24.2617 86.1276 24.9939C86.1276 25.6774 87.4971 25.9703 89.16 26.312C91.6055 26.8001 94.6378 27.4347 94.6378 29.7291C94.6378 31.7794 92.1434 32.9998 89.3067 32.9998C87.1058 32.9998 84.9538 32.3652 83.3887 31.3401H83.4376Z"
        stroke="none"
      />
      <path d="M97.377 22.3091V32.8046H99.6268V22.3091H97.377Z" stroke="none" />
      <path
        d="M108.479 22.1138C110.093 22.1138 111.805 22.6019 113.174 23.5783L112.098 24.9451C110.973 24.1641 110.044 23.7247 108.577 23.7247C106.082 23.7247 104.175 25.2868 104.175 27.5324C104.175 29.7779 106.131 31.3401 108.577 31.3401C110.093 31.3401 111.071 30.8519 112.294 30.0708L113.37 31.4377C111.951 32.4628 110.191 32.9998 108.479 32.9998C105.055 32.9998 101.827 30.9983 101.827 27.5324C101.827 24.1152 105.055 22.1138 108.479 22.1138Z"
        stroke="none"
      />
      <path
        d="M115.23 28.0694V22.3091H117.479V28.2159C117.479 30.0709 118.8 31.3401 120.756 31.3401C122.713 31.3401 124.033 30.0709 124.033 28.2159V22.3091H126.234V28.0694C126.234 30.9984 124.033 32.9999 120.707 32.9999C117.382 32.9999 115.181 31.0472 115.181 28.0694H115.23Z"
        stroke="none"
      />
      <path
        d="M134.451 27.4836C136.065 27.4836 137.092 26.8002 137.092 25.6774C137.092 24.5546 136.114 23.9688 134.451 23.9688H131.174V27.4836H134.451ZM128.924 22.2603H134.646C137.287 22.2603 139.439 23.3342 139.439 25.6286C139.439 27.4348 138.119 28.3623 136.309 28.8016L139.733 32.7558H137.092L133.962 29.0945H131.125V32.7558H128.875V22.2603H128.924Z"
        stroke="none"
      />
      <path
        d="M148.586 28.6547L146.531 24.5053L144.477 28.6547H148.634H148.586ZM149.417 30.3145H143.597L142.325 32.8529H140.026L145.504 22.3574H147.803L153.281 32.8529H150.689L149.417 30.3145Z"
        stroke="none"
      />
      <path
        d="M154.015 32.8046V31.2913L161.155 23.9688H154.21V22.3091H164.139V23.8224L156.998 31.1449H164.286V32.8046H154.015Z"
        stroke="none"
      />
      <path d="M166.731 22.3091V32.8046H168.981V22.3091H166.731Z" stroke="none" />
      <path
        d="M173.529 27.5324C173.529 29.6315 175.29 31.3401 177.833 31.3401C180.377 31.3401 182.137 29.6803 182.137 27.5324C182.137 25.4333 180.377 23.7247 177.833 23.7247C175.29 23.7247 173.529 25.3845 173.529 27.5324ZM171.182 27.5324C171.182 24.5546 173.969 22.1138 177.833 22.1138C181.746 22.1138 184.485 24.5546 184.485 27.5324C184.485 30.5102 181.697 32.951 177.833 32.951C173.921 32.951 171.182 30.5102 171.182 27.5324Z"
        stroke="none"
      />
      <path
        d="M188.789 32.8046H186.539V22.3091H188.789L195.245 29.778V22.3091H197.495V32.8046H195.245L188.789 25.3357V32.8046Z"
        stroke="none"
      />
      <path d="M200.479 22.3091V32.8046H202.777V22.3091H200.479Z" stroke="none" />
      <path d="M0.487305 29.1436V32.8048H41.7668V29.1436H0.487305Z" stroke="none" />
      <path
        d="M26.7524 9.07984L29.5402 16.0606L34.4801 13.4733L30.225 4.05176H24.0135L15.3076 20.2588C15.3076 20.2588 19.6116 20.1123 22.3506 18.0621C23.0353 17.6715 24.9917 12.5458 26.8013 9.07984H26.7524Z"
        stroke="none"
      />
      <path
        d="M34.4307 13.4247L29.4909 16.012C25.2847 18.1599 21.2741 20.0637 19.1221 20.6495C13.4486 22.2605 6.50349 23.2856 4.00912 21.0889C1.66147 19.0386 7.09041 10.3493 17.2636 8.39664C18.9265 8.00611 19.9047 8.10374 19.9047 8.15256C19.9047 8.15256 19.2199 5.17476 18.6819 4.54015C17.8994 3.61264 17.1657 3.41737 16.0408 3.56382C13.8399 3.71027 4.40039 6.1999 0.732188 14.0105C-1.32201 18.4528 1.41692 22.9439 3.17766 24.262C4.00912 24.8477 9.34024 29.6318 21.7143 24.7989C33.8927 20.0637 44.7995 12.6437 44.7995 12.6437V7.66439C44.7995 7.66439 40.0064 10.3981 34.4307 13.3759V13.4247Z"
        stroke="none"
      />
      <path
        d="M31.251 20.5521C31.6912 21.626 33.0117 24.7015 33.3541 25.629C33.7943 26.6053 34.8703 26.6053 34.8703 26.6053H40.5438L36.3376 17.8672C34.7725 18.7459 33.0606 19.6734 31.251 20.5521Z"
        stroke="none"
      />
      <path
        d="M44.8487 5.76055C44.8487 5.76055 38.3437 9.47059 36.8275 10.0564C35.4092 10.5934 34.9201 9.86112 34.7244 9.61704C34.3332 9.03124 33.7463 7.17623 33.6484 6.88333C33.6973 6.88333 34.0397 6.98096 34.8712 6.73688C35.8494 6.4928 44.7998 1.46472 44.8487 1.51354V5.76055Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default LogoAssicurazioniMono
