import React, { ReactNode } from 'react'
import styles from './Tab.module.sass'

export interface TriggerProps {
  id?: string
  icon?: ReactNode
  label?: string
  size?: 'md' | 'lg'
  active?: boolean
  bgColor?: '' | 'primary'
  onClick?: () => void
  labelId?: string
  tabIndex?: number | undefined
  onKeyDown?: (e: KeyboardEvent) => void
  buttonId?: string
}

export interface Props {
  id?: string
  active?: boolean
  banner?: string
  className?: string
  children?: ReactNode
  labelId?: string
}

const TabTrigger = (props: TriggerProps) => {
  const {
    id = '',
    icon = null,
    label = '',
    size = 'md',
    active = false,
    bgColor = '',
    onClick = undefined,
    labelId = '',
    tabIndex = undefined,
    onKeyDown = undefined,
    buttonId = '',
  } = props

  return (
    <button
      type="button"
      className={`${styles.tabTrigger} ${bgColor ? styles[`tabTrigger--${bgColor}`] : ''} ${
        size ? styles[`tabTrigger--${size}`] : ''
      } ${active ? styles['tabTrigger--active'] : ''}`}
      onClick={onClick}
      role="tab"
      aria-selected={active}
      aria-controls={id || undefined}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      id={buttonId}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {label && (
        <span className={styles.label} id={labelId}>
          {label}
        </span>
      )}
    </button>
  )
}

const Tab = (props: Props) => {
  const {
    id = '',
    active = false,
    banner = '',
    className = '',
    children = null,
    labelId = '',
  } = props

  return active ? (
    <div
      id={id || undefined}
      aria-labelledby={labelId}
      role="tabpanel"
      className={`${styles.tab} ${active ? styles['tab--active'] : ''} ${className}`}
    >
      {!!banner && (
        <div className={styles.banner}>
          <span className={styles.bannerContent}>{banner}</span>
        </div>
      )}
      {children}
    </div>
  ) : (
    <></>
  )
}

Tab.Trigger = TabTrigger

export default Tab
