import React, { useState } from 'react'
import { Add } from '@next-nx/shared-ui/icons'
import { FormError } from '@next-nx/shared-ui/atoms'
import styles from './FormFile.module.sass'

interface Props {
  id: string
  label?: string
  name?: string
  value?: any
  accept?: string
  multiple?: boolean
  className?: string
  readOnly?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  ariaLabel?: string
}

const FormFile = (props: Props) => {
  const {
    id = '',
    label = '',
    name = '',
    value = '',
    accept = undefined,
    multiple = false,
    className = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = undefined,
    onFocus = undefined,
    onChange = undefined,
  } = props

  const [focused, setFocused] = useState(false)

  const classNames = `
    ${styles.formFile}
    ${disabled ? styles['formFile--disabled'] : ''}
    ${status ? styles[`formFile--${status}`] : ''} 
    ${focused ? styles['formFile--focused'] : ''}
    ${className ? `${className}` : ''} `

  const onLocalFocus = (e: any) => {
    setFocused(true)
    if (onFocus) onFocus(e)
  }

  const onLocalBlur = (e: any) => {
    setFocused(false)
    if (onBlur) onBlur(e)
  }

  return (
    <div className={classNames}>
      <label htmlFor={id}>
        <input
          value={value}
          name={name}
          accept={accept}
          multiple={multiple}
          className={styles.input}
          type="file"
          readOnly={readOnly}
          disabled={disabled}
          onBlur={onLocalBlur}
          onFocus={onLocalFocus}
          onChange={onChange}
          id={id}
        />
        <span className={styles.styledinput}>
          <Add />
        </span>
        <span className={styles.label} dangerouslySetInnerHTML={{ __html: label }} />
      </label>
      <FormError message={errorMessage} visible={status === 'error'} />
    </div>
  )
}

export default FormFile
