import React, { useEffect, useState } from 'react'
import Select, { ActionMeta, SingleValue } from 'react-select'
import { Check, ChevronDown, Error } from '../Icons'
import { FormError } from '../'
import { useTrans } from '@next-nx/hooks'
import styles from './FormSelect.module.sass'

interface Option {
  label: string
  value: number | string
  selected?: boolean
}
export interface Props {
  id?: string
  placeholder?: string
  name?: string
  value?: number | string
  size?: 'lg' | 'md' | 'sm'
  leftIcon?: React.ReactElement
  rightIcon?: React.ReactElement
  className?: string
  disabled?: boolean
  status?: null | 'success' | 'error' | string
  variant?: '' | 'dropdown' | 'date'
  errorMessage?: string
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLElement>) => void
  onChange?: (e: { target: { name: string; value?: number | string; type: string } }) => void
  options: Option[]
}

const FormSelectDropdownIndicator = () => (
  <div className="react-select__dropdown-indicator">
    <ChevronDown />
  </div>
)

const FormSelect = (props: Props) => {
  const {
    id = '',
    placeholder = '',
    name = '',
    value = null,
    size = 'lg',
    leftIcon = undefined,
    rightIcon = undefined,
    className = '',
    disabled = false,
    status = '',
    variant = '',
    errorMessage = '',
    onBlur = undefined,
    onFocus = undefined,
    onChange = undefined,
    options = [],
  } = props

  const classNames = ` 
    ${styles.formSelect}
    ${styles[`formSelect--${size}`]}
    ${variant ? styles[`formSelect--${variant}`] : ''}
    ${status ? styles[`formSelect--${status}`] : ''}
    ${className || ''}`

  const localRightIcon =
    status === 'error' ? <Error /> : status === 'success' ? <Check /> : rightIcon

  const reactSelectClassNames = ` 
    react-select
    react-select--${size}
    ${status ? `react-select--${status}` : ''}
    ${leftIcon ? 'react-select--left-icon' : ''}
    ${localRightIcon ? 'react-select--right-icon' : ''}
  `

  const localOnChange = (newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
    if (onChange)
      onChange({
        target: {
          value: newValue?.value,
          name: name,
          type: 'react-select',
        },
      })
  }

  const t = useTrans()

  // useEffect(() => {
  //   const element = document.getElementById(id);

  //   const handleFocusIn = () => {
  //     const parentElement = element.closest('.react-select__control');
  //     if (parentElement) {
  //       parentElement.style.setProperty('outline', '2px solid #345DAC', 'important');
  //       parentElement.style.setProperty('outline-offset', '4px', 'important');

  //     }
  //   };

  //   const handleFocusOut = () => {
  //     const parentElement = element.closest('.react-select__control');
  //     if (parentElement) {
  //       parentElement.style.removeProperty('outline');
  //       parentElement.style.removeProperty('outline-offset');
  //     }
  //   };

  //   element.addEventListener('focusin', handleFocusIn);
  //   element.addEventListener('focusout', handleFocusOut);
  //   return () => {
  //     element.removeEventListener('focusin', handleFocusIn);
  //     element.removeEventListener('focusout', handleFocusOut);
  //   };
  // }, []);

  return (
    <div className={classNames}>
      <div className={styles.inputWrap}>
        {!!leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
        {!!localRightIcon && <span className={styles.rightIcon}>{localRightIcon}</span>}
        <Select
          inputId={id}
          className={reactSelectClassNames}
          classNamePrefix="react-select"
          closeMenuOnSelect
          isClearable={false}
          isSearchable={false}
          isDisabled={disabled || false}
          instanceId={name}
          name={name}
          placeholder={placeholder}
          components={{ DropdownIndicator: FormSelectDropdownIndicator }}
          onChange={localOnChange}
          onFocus={onFocus}
          onBlur={onBlur}
          options={options}
          screenReaderStatus={function screenReaderStatus(_ref) {
            const count = _ref.count
            return ''
              .concat(count.toString(), t(' risultat'))
              .concat(
                count !== 1 ? t('i') : t('o'),
                count !== 1 ? t(' disponibili') : t(' disponibile')
              )
          }}
          ariaLiveMessages={{
            guidance: function guidance(props) {
              var isSearchable = props.isSearchable,
                isMulti = props.isMulti,
                isDisabled = props.isDisabled,
                tabSelectsValue = props.tabSelectsValue,
                context = props.context

              switch (context) {
                case 'menu':
                  return t('Usa Su e Giu per scegliere le opzioni')
                    .concat(
                      isDisabled
                        ? ''
                        : t(", premi Invio per selezionare l'opzione corrente evidenziata"),
                      t(', premi Esc per uscire dal menu')
                    )
                    .concat(
                      tabSelectsValue
                        ? t(", premi Tab per selezionare l'opzione e uscire dal menu")
                        : '',
                      '.'
                    )

                case 'input':
                  return ''
                    .concat(props['aria-label'] || 'Select', t(' è evidenziato '))
                    .concat(
                      isSearchable ? t(",digitare per perfezionare l'elenco") : '',
                      t(', premi Giu per aprire il menu, ')
                    )
                    .concat(
                      isMulti ? t(' premi Sinistra per evidenziare i valori selezionati') : ''
                    )

                case 'value':
                  return t(
                    'Utilizzare sinistra e destra per alternare tra i valori focalizzati, premere Backspace per rimuovere il valore attualmente focalizzato'
                  )

                default:
                  return ''
              }
            },
            onChange: (props) => {
              var action = props.action,
                _props$label = props.label,
                label = _props$label === void 0 ? '' : _props$label,
                labels = props.labels,
                isDisabled = props.isDisabled

              switch (action) {
                case 'deselect-option':
                case 'pop-value':
                case 'remove-value':
                  return t('opzione ').concat(label, t(', deselezionata.'))

                case 'clear':
                  return t('Tutte le opzioni selezionate sono state resettate.')

                case 'initial-input-focus':
                  return t('opzione')
                    .concat(labels.length > 1 ? 's' : '', ' ')
                    .concat(labels.join(','), t(', selezionata.'))

                case 'select-option':
                  return isDisabled
                    ? t('opzione ').concat(label, t(" è disabilitata. Seleziona un'altra opzione."))
                    : t('opzione ').concat(label, t(', selezionata.'))

                default:
                  return ''
              }
            },
            onFocus: function onFocus(props) {
              var context = props.context,
                focused = props.focused,
                options = props.options,
                _props$label2 = props.label,
                label = _props$label2 === void 0 ? '' : _props$label2,
                selectValue = props.selectValue,
                isDisabled = props.isDisabled,
                isSelected = props.isSelected

              var getArrayIndex = function getArrayIndex(arr, item) {
                return arr && arr.length
                  ? ''.concat(arr.indexOf(item) + 1, t(' di ')).concat(arr.length)
                  : ''
              }

              if (context === 'value' && selectValue) {
                return t('valore ')
                  .concat(label, t(' evidenziata, '))
                  .concat(getArrayIndex(selectValue, focused), '.')
              }

              if (context === 'menu') {
                var disabled = isDisabled ? t(' disabilitata') : ''
                var status = ''
                  .concat(isSelected ? t('selezionata') : t('evidenziata'))
                  .concat(disabled)
                return t('opzione ')
                  .concat(label, ' ')
                  .concat(status, ', ')
                  .concat(getArrayIndex(options, focused), '.')
              }

              return ''
            },
            onFilter: function onFilter(props) {
              var inputValue = props.inputValue,
                resultsMessage = props.resultsMessage
              return ''
                .concat(resultsMessage)
                .concat(inputValue ? t(' per cercare il termine ') + inputValue : '', '.')
            },
          }}
          value={
            options?.find((option) => option.selected) ||
            options?.find((option) => option.value === value)
          }
          aria-describedby={status === 'error' ? `${id}_error` : undefined}
          aria-invalid={status === 'error'}
        />
      </div>
      <FormError
        id={`${id}_error`}
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormSelect
