import React from 'react'
import { PDF } from '@next-nx/shared-ui/icons'
import styles from './PdfLink.module.sass'
import { sendGTMData } from '../../../utils/gtm'
import { useTrans } from '@next-nx/hooks'

interface Props {
  name?: string
  url?: string
  className?: string
  title?: string
}

const PdfLink = (props: Props) => {
  const { name = '', url = '', className = '', title = '' } = props

  const linkId = (name.replace(' ', '_').replace(/ /g, '_').slice(0, 15) || 'allegato') + '_link'

  const t = useTrans()

  return url ? (
    <a
      className={`${styles.pdfLink} ${className ? ` ${className}` : ''}`}
      title={
        (title || t('Scarica il documento')) + ` (${url.split('.')[url.split('.').length - 1]})`
      }
      aria-labelledby={linkId}
      href={url}
      download
      onClick={() =>
        sendGTMData({
          event: 'GAevent',
          eventCategory: 'allegati',
          eventAction: name?.toGTMFormat(),
          eventLabel: 'download',
        })
      }
    >
      <span className={styles.icon}>
        <PDF />
      </span>
      {!!name && (
        <span id={linkId} className={styles.label}>
          {name}
        </span>
      )}
    </a>
  ) : (
    <></>
  )
}

export default PdfLink
