import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
  title?: string
}

function LogoVitaMono({ idVariant = '', className, title = '' }: Props) {
  return (
    <IconBase name="logo-vita-mono" w={278} h={33} className={className}>
      {title && <title>{title}</title>}
      <g clipPath={`url(#clip0_1541:4829_${idVariant})`}>
        <path
          d="M65.9109 4.32384C68.1076 4.32384 70.4041 4.94979 72.3012 6.24985L70.8534 8.03141C69.3057 7.02025 68.0576 6.44245 66.1106 6.44245C62.7657 6.44245 60.1198 8.46476 60.1198 11.4019C60.1198 14.3391 62.7657 16.3614 66.1106 16.3614C68.1575 16.3614 69.5054 15.7355 71.1529 14.6761L72.6007 16.4577C70.7036 17.7578 68.2573 18.48 65.9609 18.48C61.318 18.48 56.9746 15.8317 56.9746 11.3538C56.9746 6.82765 61.3679 4.22754 65.9609 4.22754L65.9109 4.32384Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M82.6859 11.4018C84.8326 11.4018 86.2305 10.5351 86.2305 9.04243C86.2305 7.59792 84.8826 6.77936 82.636 6.77936H78.1928V11.4018H82.636H82.6859ZM75.1475 4.56445H82.8856C86.4801 4.56445 89.3757 5.96081 89.3757 8.99428C89.3757 11.3536 87.5784 12.6055 85.1322 13.1352L89.825 18.3354H86.2305L81.987 13.5204H78.1928V18.3354H75.1475V4.56445Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M102.255 0.616089L99.9086 3.0236H97.3126L99.1098 0.616089H102.305H102.255ZM92.3701 14.3389V4.51626H105.3V6.73117H95.4155V10.0535H104.502V12.2203H95.4155V14.3389C95.4155 15.5427 96.0145 16.1205 97.2127 16.1205H105.6V18.3354H96.5138C93.9177 18.3354 92.3701 16.8427 92.3701 14.3389Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M115.535 16.1687C119.03 16.1687 121.326 14.2908 121.326 11.4981C121.326 8.70537 118.98 6.77936 115.535 6.77936H111.941V16.1687H115.535ZM108.896 4.56445H115.336C120.827 4.56445 124.522 7.30902 124.522 11.4499C124.522 15.5909 120.827 18.3354 115.336 18.3354H108.896V4.56445Z"
          fill="white"
          stroke="none"
        />
        <path d="M130.413 4.56445H127.367V18.3836H130.413V4.56445Z" fill="white" stroke="none" />
        <path
          d="M147.137 4.56445V6.77936H141.596V18.3836H138.55V6.77936H133.059V4.56445H147.137Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M163.961 12.8944L161.166 7.40526L158.37 12.8944H164.011H163.961ZM165.06 15.0612H157.172L155.474 18.3835H152.329L159.718 4.61255H162.863L170.252 18.3835H166.757L165.06 15.0612Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M179.837 4.32378C182.033 4.32378 184.33 4.94974 186.227 6.24979L184.779 8.03135C183.232 7.0202 181.984 6.4424 180.037 6.4424C176.692 6.4424 174.046 8.41656 174.046 11.4019C174.046 14.339 176.392 16.4095 179.937 16.4095C181.534 16.4095 182.982 15.9761 183.981 15.3502V12.7019H179.687V10.5833H187.076V16.3613C185.378 17.5651 182.932 18.5281 179.837 18.5281C174.445 18.5281 170.851 15.4465 170.851 11.4019C170.851 6.87575 175.244 4.27563 179.837 4.27563V4.32378Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M197.959 11.4018C200.106 11.4018 201.504 10.5351 201.504 9.04243C201.504 7.59792 200.156 6.77936 197.909 6.77936H193.466V11.4018H197.909H197.959ZM190.421 4.56445H198.159C201.754 4.56445 204.649 5.96081 204.649 8.99428C204.649 11.3536 202.852 12.6055 200.406 13.1352L205.098 18.3354H201.504L197.26 13.5204H193.466V18.3354H190.421V4.56445Z"
          fill="white"
          stroke="none"
        />
        <path d="M211.239 4.56445H208.193V18.3836H211.239V4.56445Z" fill="white" stroke="none" />
        <path
          d="M223.32 4.32384C225.517 4.32384 227.813 4.94979 229.71 6.24985L228.263 8.03141C226.715 7.02025 225.467 6.44245 223.52 6.44245C220.175 6.44245 217.529 8.46476 217.529 11.4019C217.529 14.3391 220.175 16.3614 223.52 16.3614C225.567 16.3614 226.915 15.7355 228.562 14.6761L230.01 16.4577C228.113 17.7578 225.667 18.48 223.37 18.48C218.727 18.48 214.384 15.8317 214.384 11.3538C214.384 6.82765 218.777 4.22754 223.37 4.22754L223.32 4.32384Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M233.804 11.45C233.804 14.2427 236.201 16.4094 239.646 16.4094C243.14 16.4094 245.487 14.1945 245.487 11.45C245.487 8.65725 243.09 6.49049 239.646 6.49049C236.151 6.49049 233.804 8.7054 233.804 11.45ZM230.609 11.45C230.609 7.5498 234.354 4.32373 239.646 4.32373C244.937 4.32373 248.682 7.5498 248.682 11.45C248.682 15.3501 244.937 18.5762 239.646 18.5762C234.354 18.5762 230.609 15.3501 230.609 11.45Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M263.309 18.3836H251.178V4.56445H254.223V16.1687H263.309V18.3836Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M264.807 14.3871V4.56445H277.737V6.77936H267.852V10.1017H276.938V12.2685H267.852V14.3871C267.852 15.5909 268.451 16.1687 269.649 16.1687H278.036V18.3836H268.95C266.354 18.3836 264.807 16.8909 264.807 14.3871Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M57.6738 22.6208H60.3198L64.3137 30.8064L68.3575 22.6208H70.7039L65.3621 32.9731H63.0157L57.6738 22.6208Z"
          fill="white"
          stroke="none"
        />
        <path d="M75.0974 22.6208H72.751V32.9731H75.0974V22.6208Z" fill="white" stroke="none" />
        <path
          d="M88.0272 22.6208V24.258H83.8835V32.9731H81.587V24.258H77.4434V22.6208H88.0272Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M96.265 28.8803L94.1682 24.7876L92.0714 28.8803H96.3149H96.265ZM97.1137 30.5174H91.1727L89.8747 33.0212H87.5283L93.1198 22.6689H95.4662L101.058 33.0212H98.4117L97.1137 30.5174Z"
          fill="white"
          stroke="none"
        />
        <path d="M42.6463 29.3618H0.510742V32.9731H42.6463V29.3618Z" fill="white" stroke="none" />
        <path
          d="M27.3198 9.57202L30.1655 16.4575L35.2077 13.9055L30.8644 4.61255H24.5241L15.6377 20.5984C15.6377 20.5984 20.031 20.454 22.8267 18.4317C23.5256 18.0465 25.5226 12.9907 27.3697 9.57202H27.3198Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M35.1584 13.8574L30.1161 16.4094C25.8227 18.528 21.729 20.4059 19.5323 20.9837C13.7412 22.5726 6.65203 23.5838 4.10592 21.417C1.70959 19.3947 7.25111 10.824 17.6352 8.89797C19.3326 8.51277 20.3311 8.60907 20.3311 8.65722C20.3311 8.65722 19.6322 5.72005 19.083 5.0941C18.2842 4.17924 17.5354 3.98664 16.3871 4.13109C14.1406 4.27554 4.50531 6.73121 0.761043 14.4352C-1.33575 18.8169 1.45997 23.2467 3.25722 24.5468C4.10593 25.1246 9.5476 29.8433 22.1783 25.0765C34.6093 20.4059 45.7422 13.087 45.7422 13.087V8.17571C45.7422 8.17571 40.8497 10.8721 35.1584 13.8093V13.8574Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M31.9131 20.8874C32.3624 21.9467 33.7103 24.9802 34.0598 25.895C34.5091 26.858 35.6074 26.858 35.6074 26.858H41.3986L37.1051 18.2391C35.5076 19.1058 33.7603 20.0207 31.9131 20.8874Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M45.7919 6.29782C45.7919 6.29782 39.152 9.95724 37.6044 10.535C36.1566 11.0647 35.6574 10.3424 35.4577 10.1017C35.0583 9.52388 34.4592 7.69417 34.3594 7.40527C34.4093 7.40527 34.7588 7.50157 35.6075 7.26082C36.6059 7.02007 45.742 2.0606 45.7919 2.10875V6.29782Z"
          fill="white"
          stroke="none"
        />
      </g>
      <defs>
        <clipPath id={`clip0_1541:4829_${idVariant}`}>
          <rect width="278" height="33" fill="white" stroke="none" />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default LogoVitaMono
