/* eslint-disable react/button-has-type */
import React, { ReactNode } from 'react'
import Link from 'next/link'
import { Loader } from '@next-nx/shared-ui/atoms'
import styles from './Button.module.sass'

export interface Props {
  id?: string
  label?: string
  labelHtml?: string
  labelMobile?: string
  type?: 'button' | 'submit'
  variant?: 'primary' | 'secondary' | 'tertiary' | 'tertiary2' | 'ghost' | 'ghostleft'
  size?: 'sm' | 'md' | 'lg'
  color?: '' | 'white'
  responsive?: boolean
  disabled?: boolean
  leftIcon?: ReactNode // Se c'è solo l'icona senza label, si usa leftIcon come unica icona (non supportato il caso con 2 icone senza label)
  rightIcon?: ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  href?: string
  newPage?: boolean
  className?: string
  loading?: boolean
  external?: boolean
  ariaLabel?: string
  title?: string
  tabIndex?: number | undefined
}

const Button = (props: Props) => {
  const {
    id = '',
    label = '',
    labelHtml = '',
    labelMobile = '',
    type = 'button',
    variant = 'primary',
    color = '',
    size = 'lg',
    responsive = false,
    disabled = false,
    leftIcon = null,
    rightIcon = null,
    onClick = undefined,
    href = null,
    newPage = false,
    className = '',
    loading = false,
    external = false,
    ariaLabel = '',
    title = '',
    tabIndex = undefined,
  } = props

  const classeNames = `
    ${styles.button}
    ${styles[`button--${variant}`]}
    ${styles[`button--${size}`]}
    ${color ? styles[`button--${color}`] : ''}
    ${responsive ? styles['button--resposive'] : ''}
    ${loading ? styles['button--loading'] : ''}
    ${className ? ` ${className}` : ''}`

  const buttonContent = (
    <>
      {/* se non c'è la label, considero la leftIcon come l'unica icona */}
      {leftIcon && <span className={`${styles.icon} ${styles['icon--left']}`}>{leftIcon}</span>}
      {label && (
        <span className={`${styles.label} ${labelMobile ? styles['label--desktop'] : ''}`}>
          {label}
        </span>
      )}
      {labelHtml && !label && (
        <span
          className={`${styles.label} ${styles['label--html']} ${
            labelMobile ? styles['label--desktop'] : ''
          }`}
          dangerouslySetInnerHTML={{ __html: labelHtml }}
        />
      )}
      {labelMobile && (
        <span className={`${styles.label} ${styles['label--mobile']}`}>{labelMobile}</span>
      )}
      {rightIcon && <span className={`${styles.icon} ${styles['icon--right']}`}>{rightIcon}</span>}
      {loading && (
        <span className={styles.loader}>
          <Loader />
        </span>
      )}
    </>
  )

  return href ? (
    external ? (
      <a
        id={id || undefined}
        className={classeNames}
        href={href}
        target={newPage ? '_blank' : undefined}
        rel="noopener noreferrer"
        onClick={onClick}
        aria-label={ariaLabel || undefined}
        title={title || undefined}
        tabIndex={tabIndex}
      >
        {buttonContent}
      </a>
    ) : (
      <Link legacyBehavior href={href}>
        <a
          id={id || undefined}
          className={classeNames}
          rel="noopener noreferrer"
          target={newPage ? '_blank' : undefined}
          onClick={onClick}
          aria-label={ariaLabel || undefined}
          title={title || undefined}
          tabIndex={tabIndex}
        >
          {buttonContent}
        </a>
      </Link>
    )
  ) : (
    <button
      id={id || undefined}
      type={type}
      className={classeNames}
      disabled={disabled}
      onClick={onClick}
      aria-label={ariaLabel || undefined}
      title={title || undefined}
      tabIndex={tabIndex}
    >
      {buttonContent}
    </button>
  )
}

export default Button
