import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Check, Error, Calendar } from '@next-nx/shared-ui/icons'
import { FormError, FormSelect } from '@next-nx/shared-ui/atoms'
import styles from './FormDate.module.sass'
import { datePickerFormat } from '@next-nx/utils/safe'
import range from 'lodash/range'
import match from 'date-fns/locale/it/_lib/match/index'

interface Props {
  id?: string
  placeholder?: string
  name?: string
  value?: string
  size?: 'lg' | 'md'
  leftIcon?: React.ReactElement
  rightIcon?: React.ReactElement
  className?: string
  readOnly?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  bgVariant?: '' | 'white'
  errorMessage?: string
  popperPlacement?: string
  minDate?: Date
  maxDate?: Date
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: any) => void
  required?: boolean
}

interface renderCustomHeaderProps {
  date: number
  changeYear: (y: string | number | undefined) => void
  changeMonth: (m: string | number | undefined) => void
  decreaseMonth: (e: any) => void
  increaseMonth: (e: any) => void
  prevMonthButtonDisabled: boolean
  nextMonthButtonDisabled: boolean
}

const FormDate = (props: Props) => {
  const {
    id = '',
    placeholder = '',
    name = '',
    value = '',
    size = 'lg',
    leftIcon = undefined,
    rightIcon = undefined,
    className = '',
    readOnly = false,
    disabled = false,
    status = '',
    bgVariant = '',
    errorMessage = '',
    popperPlacement = undefined,
    minDate = undefined,
    maxDate = undefined,
    onBlur = undefined,
    onFocus = undefined,
    onChange = undefined,
    required = false,
  } = props

  const classNames = `
    ${styles.formDate}
    ${bgVariant ? styles[`formDate--bg-${bgVariant}`] : ''}
    ${size ? styles[`formDate--${size}`] : ''}
    ${status ? styles[`formDate--${status}`] : ''}
    ${className || ''}`

  const localRightIcon =
    status === 'error' ? <Error /> : status === 'success' ? <Check /> : rightIcon

  const days = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab']
  const months = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ]
  const years = range(
    minDate ? minDate.getFullYear() : 1900,
    maxDate ? maxDate.getFullYear() + 1 : new Date().getFullYear() + 10,
    1
  )
  const locale = {
    localize: {
      day: (n: number) => days[n],
      month: (n: number) => months[n],
    },
    formatLong: {
      date: () => 'dd/MM/yyyy',
    },
    match: match,
  }

  const localOnChange = (newValue: Date | null) => {
    if (onChange)
      onChange({
        target: {
          value: newValue ? datePickerFormat(newValue) : null,
          name: name,
          type: 'react-datepicker',
        },
      })
  }

  const CustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: renderCustomHeaderProps) => {
    return (
      <div className={styles.header}>
        <button
          type="button"
          className={'react-datepicker__navigation react-datepicker__navigation--previous'}
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"></span>
        </button>
        <FormSelect
          size="md"
          value={new Date(date).getFullYear()}
          onChange={({ target: { value } }) => changeYear(value)}
          options={years.map((x) => ({ label: x.toString(), value: x }))}
          className={'react-datepicker__year-select'}
          variant="date"
        />
        <FormSelect
          size="md"
          value={months[new Date(date).getMonth()]}
          onChange={({ target: { value } }) =>
            value && changeMonth(months.indexOf(value.toString()))
          }
          options={months.map((x) => ({ label: x, value: x }))}
          className={'react-datepicker__month-select'}
          variant="date"
        />
        <button
          type="button"
          className={'react-datepicker__navigation react-datepicker__navigation--next'}
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"></span>
        </button>
      </div>
    )
  }

  // per salvare il valore corrente e capire se l'utente ha cancellato o aggiunto un carattere
  const [rawInput, setRawInput] = useState()

  const inputRef = useRef(null)

  return (
    <div className={classNames}>
      <div className={styles.inputWrap}>
        {!!leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
        {!!localRightIcon && <span className={styles.rightIcon}>{localRightIcon}</span>}
        <span className={styles.calendarIcon}>{<Calendar />}</span>
        <DatePicker
          id={id}
          name={name}
          placeholderText={placeholder}
          customInput={<input ref={inputRef} />}
          onChangeRaw={(e) => {
            // Per inserimento manuale della data => aggiunta dello / automaticamente
            let newValue = e.target.value
            if (newValue) {
              if (newValue.includes('//')) {
                newValue = newValue.replaceAll('//', '/')
              }
              if (newValue.length > 10) {
                e.preventDefault()
              }
              if (rawInput?.length > newValue.length) {
                // cancellato carattere
              } else {
                // aggiunto carattere
                e.target.value = newValue
                  .replace(/^(\d\d)$/g, '$1/')
                  .replace(/^(\d\d)(\d)$/g, '$1/$2')
                  .replace(/^(\d\d\/\d\d)$/g, '$1/')
                  .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                  .replace(/[^\d\/]/g, '')
                  .replace(/^(\d\d)(\d\d)/g, '$1/$2')
              }
            }
            setRawInput(e.target.value)
          }}
          selected={value ? Date.parse(value) : null}
          onChange={localOnChange}
          // onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          //   e.preventDefault()
          // }}
          className={styles.input}
          wrapperClassName={styles.wrapper}
          disabled={disabled}
          readOnly={readOnly}
          onBlur={onBlur}
          onFocus={onFocus}
          locale={locale}
          dateFormat="dd/MM/yyyy"
          popperPlacement={popperPlacement}
          minDate={minDate}
          maxDate={maxDate}
          autoComplete="off"
          fixedHeight
          renderCustomHeader={CustomHeader}
          calendarStartDay={1}
          required={required}
          aria-required={required}
          aria-describedby={status === 'error' ? `${id}_error` : undefined}
          aria-invalid={status === 'error' || undefined}
        />
      </div>
      <FormError
        id={`${id}_error`}
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormDate
