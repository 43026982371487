import React from 'react'
import { FormError } from '../'
import styles from './FormTextarea.module.sass'

interface Props {
  id?: string
  placeholder?: string
  name?: string
  value?: string
  size?: 'lg' | 'md'
  className?: string
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  required?: boolean
}

const FormTextarea = (props: Props) => {
  const {
    id = '',
    placeholder = '',
    name = '',
    value = '',
    size = 'lg',
    className = '',
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = undefined,
    onFocus = undefined,
    onChange = undefined,
    required = false,
  } = props

  const classNames = `
    ${styles.formTextarea}
    ${styles[`formTextarea--${size}`]}
    ${status ? styles[`formTextarea--${status}`] : ''}
    ${className || ''}`

  return (
    <div className={classNames}>
      <textarea
        id={id}
        name={name}
        value={value}
        className={styles.input}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        required={required}
        aria-required={required}
        aria-describedby={status === 'error' ? `${id}_error` : undefined}
        aria-invalid={status === 'error' || undefined}
      />
      <FormError
        id={`${id}_error`}
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormTextarea
