import { usePagination, useTrans } from 'libs/shared-ui/hooks'
import { DOTS } from 'libs/shared-ui/hooks/usePagination'
import { ChevronLeft, ChevronRight } from '../Icons'
import styles from './Pagination.module.sass'

interface Props {
  className?: string
  onPageChange?: any
  totalCount?: number
  siblingCount?: number
  currentPage?: number
  pageSize?: number
}

const Pagination = (props: Props) => {
  const {
    className = '',
    onPageChange = () => {},
    totalCount = 0,
    siblingCount = 1,
    currentPage = 1,
    pageSize = 1,
  } = props

  const paginationRange = usePagination({
    totalCount,
    siblingCount,
    currentPage,
    pageSize,
  })

  const t = useTrans()

  if (currentPage === 0 || paginationRange == undefined || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange[paginationRange.length - 1]

  return paginationRange.length > 0 ? (
    <div className={`${styles.pagination} ${className || ''}`}>
      {currentPage > 1 ? (
        <button
          className={styles.prev}
          onClick={() => onPrevious()}
          type={'button'}
          aria-label={t('Pagina precedente')}
        >
          <ChevronLeft />
        </button>
      ) : (
        <span className={`${styles.prev} ${styles['prev--disabled']}`}>
          <ChevronLeft />
        </span>
      )}
      {paginationRange.map((item, index) =>
        item === DOTS ? (
          <button
            className={`${styles.item}`}
            key={index}
            type="button"
            aria-label={t('Pagina ') + item.toString()}
          >
            {item}
          </button>
        ) : (
          <button
            key={index}
            onClick={() => onPageChange(item)}
            className={`${styles.item} ${currentPage === item ? styles['item--active'] : ''}`}
            type={'button'}
            aria-label={t('Pagina ') + item.toString()}
          >
            {item}
          </button>
        )
      )}
      {currentPage < lastPage ? (
        <button
          className={styles.next}
          onClick={() => onNext()}
          type={'button'}
          aria-label={t('Pagina successiva')}
        >
          <ChevronRight />
        </button>
      ) : (
        <span className={`${styles.next} ${styles['next--disabled']}`}>
          <ChevronRight />
        </span>
      )}
    </div>
  ) : (
    <></>
  )
}

export default Pagination
