import React from 'react'
import { FormError } from '../'
import { Check, Error } from '../Icons'
import styles from './FormInput.module.sass'

interface Props {
  id?: string
  type?: 'text' | 'number' | 'email' | 'tel'
  placeholder?: string
  name?: string
  value?: string | number
  step?: string | number
  min?: string | number
  size?: 'lg' | 'md'
  leftIcon?: React.ReactElement
  rightIcon?: React.ReactElement
  className?: string
  readOnly?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  bgVariant?: '' | 'white'
  errorMessage?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  ariaLabel?: string
  required?: boolean
}

const FormInput = (props: Props) => {
  const {
    id = '',
    type = 'text',
    placeholder = '',
    name = '',
    value = '',
    step = '',
    min = '',
    size = 'lg',
    leftIcon = undefined,
    rightIcon = undefined,
    className = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    bgVariant = '',
    onBlur = undefined,
    onFocus = undefined,
    onChange = undefined,
    required = false,
  } = props

  const classNames = `
    ${styles.formInput}
    ${bgVariant ? styles[`formInput--bg-${bgVariant}`] : ''}
    ${size ? styles[`formInput--${size}`] : ''}
    ${status ? styles[`formInput--${status}`] : ''}
    ${className || ''}`

  const localRightIcon =
    status === 'error' ? <Error /> : status === 'success' ? <Check /> : rightIcon

  const optional = {
    ...(!!min && { min: min }),
    ...(!!step && { step: step }),
  }

  return (
    <div className={classNames}>
      <div className={styles.inputWrap}>
        {!!leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
        {!!localRightIcon && <span className={styles.rightIcon}>{localRightIcon}</span>}
        <input
          id={id}
          type={type}
          name={name}
          value={value}
          className={styles.input}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          autoComplete="off"
          required={required}
          aria-required={required}
          aria-describedby={status === 'error' ? `${id}_error` : undefined}
          aria-invalid={status === 'error' || undefined}
          {...optional}
        />
      </div>
      <FormError
        id={`${id}_error`}
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormInput
