import React, { useState, useEffect, useRef } from 'react'
import { BootstrapOverlayTrigger, BootstrapTooltip } from '@next-nx/shared-ui/atoms'

export interface Props {
  label: any
  id: string
  className?: string
  pos?: 'top' | 'right' | 'bottom' | 'left'
  size?: string
  fullWidth?: boolean
  autoWidth?: boolean
  children?: any
}

const Tooltip = (mainProps: Props) => {
  const {
    label = '',
    id = '',
    pos = 'top',
    size = '',
    className = '',
    fullWidth = false,
    autoWidth = false,
    children = null,
  } = mainProps

  const [showTooltip, setShowTooltip] = useState<boolean | undefined>(undefined)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter') {
      setShowTooltip(true)
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => setShowTooltip(undefined), 2000)
    }
  }

  const handleMouseOver = () => {
    setShowTooltip(true)
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => setShowTooltip(undefined), 2000)
  }

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [])

  const renderTooltip = (props: any) => (
    <BootstrapTooltip
      id={id}
      className={`tooltip ${size ? `tooltip--${size}` : ''} ${className ? ` ${className}` : ''}`}
      {...props}
      aria-live={'polite'}
    >
      {label}
    </BootstrapTooltip>
  )

  return (
    <BootstrapOverlayTrigger
      placement={pos}
      delay={{ show: 50, hide: 50 }}
      overlay={renderTooltip}
      trigger="hover"
      show={showTooltip}
    >
      <span
        className={`tooltip-container ${fullWidth ? 'tooltip-container__full' : ''} ${
          autoWidth ? 'tooltip-container__auto' : ''
        }`}
        onKeyDown={handleKeyDown}
        onMouseOver={handleMouseOver}
      >
        {children}
      </span>
    </BootstrapOverlayTrigger>
  )
}

export default Tooltip
