import React, { useState, useRef, useEffect } from 'react'
import { ChevronDown, ChevronUp } from '@next-nx/shared-ui/icons'
import styles from './Accordion.module.sass'

interface Props {
  label: string
  content: string
  isOpen?: boolean
  className?: string
  onClick?: () => void
}

const Accordion = (props: Props) => {
  const {
    label = '',
    content = '',
    isOpen = false,
    className = '',
    onClick = () => undefined,
  } = props

  const [open, setOpen] = useState(false)
  const innerContentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen) setOpen(true)
  }, [])

  const contentId = `${label.replaceAll(' ', '_').slice(0, 15)}_panel`
  const labelId = `${label.replaceAll(' ', '_').slice(0, 15)}_label`

  return (
    <div
      className={`${styles.accordion} ${open ? styles['accordion--open'] : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <button
        type="button"
        className={styles.trigger}
        onClick={() => {
          setOpen(!open)
          onClick()
        }}
        aria-controls={contentId}
        aria-expanded={open}
      >
        <span id={labelId}>{label}</span>
        <span className={styles.arrow}>{open ? <ChevronUp /> : <ChevronDown />}</span>
      </button>
      <div
        id={contentId}
        className={styles.content}
        style={{
          maxHeight: innerContentRef?.current && open ? innerContentRef.current.clientHeight : 0,
          visibility: innerContentRef?.current && open ? 'visible' : 'hidden',
        }}
        aria-labelledby={labelId}
      >
        <div
          className={`${styles.innerContent} custom-content`}
          ref={innerContentRef}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  )
}

export default Accordion
