/* eslint-disable react/no-array-index-key */
import React from 'react'
// import { BreadcrumbJsonLd } from 'next-seo'
import { ChevronRight } from '../Icons'
import styles from './Breadcrumbs.module.sass'

interface ItemProps {
  titolo: string
  url?: string
  last?: boolean
  variant?: '' | 'white'
}
interface Props {
  breadcrumbs: {
    titolo: string
    url?: string
  }[]
  variant?: '' | 'white'
  mobileHideLast?: boolean
  className?: string
}

const BreadcrumbsItem = (props: ItemProps) => {
  const { titolo = '', url = '', last = false, variant = '' } = props

  return (
    <div className={`${styles.item} ${variant ? styles[`item--${variant}`] : ''}`}>
      {last || !url ? (
        <span className={`${styles.label} ${last ? styles['label--last'] : ''}`}>{titolo}</span>
      ) : (
        <a className={`${styles.label} ${styles['label--link']}`} href={url}>
          {titolo}
        </a>
      )}
      {!last && <ChevronRight className={styles.icon} />}
    </div>
  )
}

const Breadcrumbs = (props: Props) => {
  const { breadcrumbs = [], variant = '', mobileHideLast = false, className = '' } = props

  /*
    const jsonLdItems = breadcrumbs.map((item, i) => ({
      position: i + 1,
      name: item.label ? item.label.replace(/"/g, '') : '',
      item: `${DOMAIN}${item.url || '/'}`, // per homepage
    }))
  */

  return breadcrumbs?.length > 0 ? (
    <section
      className={`${styles.breadcrumbs} ${
        mobileHideLast ? styles['breadcrumbs--mobile-hide-last'] : ''
      } ${className ? ` ${className}` : ''}`}
    >
      {breadcrumbs.map((item, index) => (
        <BreadcrumbsItem
          titolo={item.titolo}
          url={item.url === '' ? '/' : item.url}
          last={index === breadcrumbs.length - 1}
          variant={variant}
          key={index}
        />
      ))}
      {/* <BreadcrumbJsonLd itemListElements={item} /><JsonLd item={jsonLdItems} type="breadcrumbs" /> */}
    </section>
  ) : (
    <></>
  )
}

Breadcrumbs.Item = BreadcrumbsItem

export default Breadcrumbs
