import React, { ReactNode } from 'react'
import { FormError, Tooltip } from '../'
import { Info } from '../Icons'
import { Props as TooltipProps } from '../Tooltip/Tooltip'
import styles from './FormField.module.sass'

interface Props {
  id?: string
  label?: string
  layout?: 'sm' | 'md' | 'lg' | 'full'
  space?: 'none' | 'sm' | 'md' | 'lg' | 'xl'
  flexFrom?: 'sm' | 'md' | 'lg'
  status?: '' | 'success' | 'error'
  required?: boolean
  errorMessage?: string | string[] // inserito Any a causa di Formik
  className?: string
  helper?: TooltipProps
  children?: ReactNode
}

const FormField = (props: Props) => {
  const {
    id = '',
    label = '',
    layout = 'md',
    space = 'md',
    flexFrom = 'md',
    status = '',
    required = false,
    errorMessage = '',
    className = '',
    helper = null,
    children = null,
  } = props

  const classNames = `
    ${styles.formField}
    ${layout ? styles[`formField--layout-${layout}`] : ''}
    ${space ? styles[`formField--space-${space}`] : ''}
    ${flexFrom ? styles[`formField--flexFrom-${flexFrom}`] : ''}
    ${status ? styles[`formField--${status}`] : ''}
    ${required ? styles[`formField--required`] : ''}
    ${className || ''}`

  return (
    <div className={classNames}>
      {(!!label || !!helper) && (
        <div className={styles.head}>
          {!!label && (
            <label className={styles.label} htmlFor={id}>
              {label}
            </label>
          )}
          {helper && (
            <div className={styles.helperContainer}>
              <Tooltip {...helper}>
                <span className={styles.helper} tabIndex={0}>
                  <Info />
                </span>
              </Tooltip>
            </div>
          )}
        </div>
      )}
      <div className={styles.children}>{children}</div>
      <FormError
        id={`${id}_error`}
        message={errorMessage as string}
        visible={status === 'error'}
        className={styles.error}
      />
    </div>
  )
}

export default FormField
