import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function MessageError({ idVariant = '', className }: Props) {
  return (
    <IconBase name="message-error" w={24} className={className}>
      <path
        d="M13.286 2.17043C12.7033 1.19998 11.2967 1.19998 10.714 2.17043L1.22255 17.9778C0.622235 18.9776 1.34237 20.25 2.50853 20.25H21.4915C22.6576 20.25 23.3778 18.9776 22.7775 17.9778L13.286 2.17043Z"
        stroke="#D80B1F"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 9V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V9C11 8.44772 11.4477 8 12 8C12.5523 8 13 8.44772 13 9ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
        fill="#D80B1F"
        stroke="none"
      />
    </IconBase>
  )
}

export default MessageError
