import React, { ReactNode } from 'react'

interface Props {
  name: string
  className?: string
  w: number
  h?: number
  x?: number
  y?: number
  style?: any
  fill?: string
  children: ReactNode
}

const IconBase = ({
  name,
  className,
  w,
  h,
  x = 0,
  y = 0,
  style = null,
  fill = null,
  children,
}: Props) => (
  <svg
    viewBox={`${x} ${y} ${w} ${h ? h : w}`}
    xmlns="http://www.w3.org/2000/svg"
    className={`icon icon--${name}${className ? ' ' + className : ''}`}
    style={style}
    fill={fill}
    aria-hidden="true"
  >
    {children}
  </svg>
)

export default IconBase
