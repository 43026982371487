import React, { useEffect, useRef, useState } from 'react'
import { Close } from '@next-nx/shared-ui/icons'
import styles from './Modal.module.sass'
import { useTrans } from '@next-nx/hooks'

interface Props {
  id?: string
  open: boolean
  setOpen: (v: boolean) => void
  closeButton?: boolean
  onCloseClick?: (e?: any) => void
  className?: string
  overlayClassName?: string
  buttons?: any
  children?: any
  ariaLabel?: string
}

const Modal = (props: Props) => {
  const {
    id = '',
    open = false,
    setOpen = () => undefined,
    closeButton = false,
    onCloseClick = () => undefined,
    className = '',
    overlayClassName = '',
    buttons = undefined,
    children = null,
    ariaLabel = '',
  } = props

  const t = useTrans()

  useEffect(() => {
    if (open) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')
  }, [open])

  const close = (e: React.MouseEvent<HTMLElement>) => {
    if (setOpen) setOpen(false)
    e.stopPropagation()
  }

  const [prevActiveElement, setPrevActiveElement] = useState(null)
  const ref = useRef(null)
  const closeRef = useRef(null)

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        if (onCloseClick) onCloseClick()
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => document.removeEventListener('keydown', keyDownHandler)
  }, [])

  useEffect(() => {
    if (open) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')

    if (open) {
      if (ref.current) {
        // Salvo l'elemento che aveva il focus prima
        setPrevActiveElement(document.activeElement)

        ref.current.showModal()
        if (onCloseClick) {
          // Listener per click fuori dai bounds (backdrop)
          const handleBackdropClick = (e) => {
            var rect = ref.current.getBoundingClientRect()
            var isInDialog =
              rect.top <= e.clientY &&
              e.clientY <= rect.top + rect.height &&
              rect.left <= e.clientX &&
              e.clientX <= rect.left + rect.width
            if (!isInDialog) {
              onCloseClick()
            }
          }
          ref.current.addEventListener('click', handleBackdropClick)
          return () => ref.current?.removeEventListener('click', handleBackdropClick)
        }
      }
    } else {
      // rimetto il focus sull'elemento che aveva il focus prima
      if (prevActiveElement) prevActiveElement.focus()
    }
  }, [open])

  const handleFocus = (e: any) => {
    // Verifica se l'elemento che ha ricevuto il focus è all'interno della modale
    if (ref.current && !ref.current.contains(e.target)) {
      // Riporta il focus al primo elemento interattivo all'interno della modale
      closeRef.current.focus()
    }
  }
  useEffect(() => {
    document.addEventListener('focus', handleFocus, true)

    return () => {
      document.removeEventListener('focus', handleFocus, true)
    }
  }, [])

  return open ? (
    <>
      <div className={`${styles.overlay} ${overlayClassName}`} />
      <dialog
        className={`${styles.modal} ${className ? ` ${className}` : ''}`}
        id={id}
        hidden={!open}
        aria-modal
        aria-labelledby={ariaLabel ? undefined : id ? `${id}_title` : undefined}
        aria-label={ariaLabel ? ariaLabel : undefined}
        ref={ref}
      >
        {closeButton && (
          <button
            type="button"
            className={styles.close}
            onClick={(e) => {
              close(e)
              onCloseClick()
            }}
            ref={closeRef}
            aria-label={t('Chiudi')}
          >
            <Close />
          </button>
        )}
        <div className={styles.body}>{children}</div>
        {buttons && <div className={styles.buttons}>{buttons}</div>}
      </dialog>
    </>
  ) : (
    <></>
  )
}

export default Modal
