import React, { ReactNode } from 'react'
import Link from 'next/link'
import { Loader } from '../Icons'
import styles from './Chip.module.sass'

interface Props {
  label?: string
  labelHtml?: string
  variant?: 'dark' | 'light' | 'blue' | 'neutral'
  size?: 'sm' | 'md' | 'lg' | 'xl'
  weight?: 'bold' | 'regular'
  active?: boolean
  responsive?: boolean
  disabled?: boolean
  leftIcon?: ReactNode // Se c'è solo l'icona senza label, si usa leftIcon come unica icona (non supportato il caso con 2 icone senza label)
  rightIcon?: ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  href?: string
  newPage?: boolean
  className?: string
  loading?: boolean
  external?: boolean
  options?: any
  onFocus?: (e: any) => void
  role?: string | undefined
  ariaSelected?: boolean | undefined
  ariaControls?: string | undefined
}

const Chip = (props: Props) => {
  const {
    label = '',
    labelHtml = '',
    variant = 'dark',
    size = 'md',
    weight = 'bold',
    active = false,
    responsive = false,
    disabled = false,
    leftIcon = undefined,
    rightIcon = undefined,
    onClick = undefined,
    href = null,
    newPage = false,
    className = '',
    loading = false,
    external = false,
    options = {},
    onFocus = undefined,
    role = undefined,
    ariaSelected = undefined,
    ariaControls = undefined,
  } = props

  const classeNames = `
    ${styles.chip}
    ${styles[`chip--${variant}`]}
    ${styles[`chip--${size}`]}
    ${styles[`chip--fw-${weight}`]}
    ${onClick || href ? '' : styles[`chip--no-effects`]}
    ${active ? styles[`chip--active`] : ''}
    ${responsive ? styles['chip--resposive'] : ''}
    ${loading ? styles['chip--loading'] : ''}
    ${className ? ` ${className}` : ''}`

  const content = (
    <>
      {/* se non c'è la label, considero la leftIcon come l'unica icona */}
      {leftIcon && (
        <span className={`${styles.icon} ${label ? styles['icon--left'] : ''}`}>{leftIcon}</span>
      )}
      {label && <span className={styles.label}>{label}</span>}
      {labelHtml && !label && (
        <span className={styles.label} dangerouslySetInnerHTML={{ __html: labelHtml }} />
      )}
      {rightIcon && <span className={`${styles.icon} ${styles['icon--right']}`}>{rightIcon}</span>}
      {loading && (
        <span className={styles.loader}>
          <Loader />
        </span>
      )}
    </>
  )

  return href ? (
    external ? (
      <a
        className={classeNames}
        href={href}
        target={newPage ? '_blank' : undefined}
        rel="noopener noreferrer"
        onClick={onClick}
        aria-label={label}
        role={role}
        aria-selected={ariaSelected}
        aria-controls={ariaControls}
      >
        {content}
      </a>
    ) : (
      <Link legacyBehavior href={href}>
        <a
          className={classeNames}
          onClick={onClick}
          aria-label={label}
          role={role}
          aria-selected={ariaSelected}
          aria-controls={ariaControls}
        >
          {content}
        </a>
      </Link>
    )
  ) : onClick ? (
    <button
      onFocus={onFocus}
      type="button"
      className={classeNames}
      disabled={disabled}
      onClick={onClick}
      aria-label={label}
      role={role}
      aria-selected={ariaSelected}
      aria-controls={ariaControls}
      {...options}
    >
      {content}
    </button>
  ) : (
    <div className={classeNames} {...options}>
      {content}
    </div>
  )
}

export default Chip
